import React from 'react'
import { Helmet } from 'react-helmet'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import PageLink from '../components/global/PageLink'
import pluralize from 'pluralize'
import LeftArrowIcon from '../graphics/icons/left-arrow-icon.svg'
import styled from '@emotion/styled'
import tw from 'twin.macro'

import Layout from '../components/Layout'
import Container, { Col } from '../components/global/Container'
import PartCategories from '../components/parts/PartCategories'
import PageHeading from '../components/global/PageHeading'
import PartsTable from '../components/parts/PartsTable'
import PartsCategoryHeading from '../components/parts/PartsCategoryHeading'

const TypesCards = ({ className, categorySlug, types}) => {
  return (
    <ul tw="list-none flex flex-wrap -mx-2 md:-mx-3 pt-8" className={className}>
      {types.map(type=> (
        <li tw="w-1/2 sm:w-1/3 pb-0 sm:pb-2 md:pb-3 px-1 sm:px-3" key={type.id}>
          <PageLink tw="block rounded-lg bg-gray-light p-2 xs:p-4 lg:p-8 text-sm h-full relative flex justify-center items-center"
            to={`/parts/${categorySlug}/${type.slug}`}>
            <div tw="w-full pt-full relative mb-12">
              <img src={type.image != null ? type.image.thumbnail : '/images/part-placeholder.jpg'} tw="object-contain absolute w-full h-full top-0 left-0" />
            </div>
            <span tw="absolute w-full bottom-0 left-0 px-3 pb-4 xs:pb-5 sm:pb-8 text-center">
              {type.name}
            </span>
          </PageLink>
        </li>
      ))}
    </ul>

  )
}
TypesCards.propTypes = {
  className: PropTypes.string,
  categorySlug: PropTypes.string.isRequired,
  types: PropTypes.array.isRequired,
}

const PartsCategoryPageComponent = ({ data }) => {

  const tableData = data.parts.edges.map(part => ({
    number: part.node.data.Part_Number,
    query: `<strong>${part.node.data.Part_Number}</strong><small>${part.node.data.Part_Description}</small>`,
    variation: part.node.data.Part_Variation ? part.node.data.Part_Variation[0].data.Variation : null,
    description: part.node.data.Part_Description,
    eq1: part.node.data.Part_Equivalent_1,
    eq2: part.node.data.Part_Equivalent_2,
    eq3: part.node.data.Part_Equivalent_3,
  }))

  const category = data.category.edges.map(cat => ({
    name: cat.node.data.Category_Name,
    introduction: cat.node.data.Category_Introduction,
    details: cat.node.data.Category_Details,
    slug: cat.node.data.Category_Slug,
    types: cat.node.data.Category_Types.map(type => ({
      id: type.id,
      name: pluralize(type.data.Type_Name),
      slug: type.data.Type_Slug,
      image: type.data.Type_Image ? {
        full: type.data.Type_Image[0].url,
        thumbnail: type.data.Type_Image[0].thumbnails.large.url
      } : null
    })),
  }))[0]

  return (
    <Layout>
      <Helmet titleTemplate="%s - Techna NDT">
        <title>{category.name}</title>
        <meta name="description" content="{category.description}" />
      </Helmet>
      <PageHeading tw="hidden lg:block" to="/parts" title="Parts Database" />
      <Container>
        <PartCategories />
        <Col tw="flex-1 pt-10 overflow-auto">
          <PageLink to="/parts" tw="lg:hidden flex -mt-4 mb-4 xs:mb-0 xs:mt-1 text-sm md:text-base">
            <LeftArrowIcon tw="mr-2" />
            Parts Database
          </PageLink>
          <div tw="lg:pl-4">
            <PartsTable
              heading={<PartsCategoryHeading category={category} />}
              data={tableData}
              category={category}
              placeholder={<TypesCards categorySlug={category.slug} types={category.types}/>}
            />
          </div>
        </Col>
      </Container>
    </Layout>
  )
}

PartsCategoryPageComponent.propTypes = {
  helmet: PropTypes.object,
  data: PropTypes.object.isRequired,
}

const PartsCategoryPage = styled(PartsCategoryPageComponent)`
  ${tw``}
`

export default PartsCategoryPage

export const pageQuery = graphql`
  query PartsCategoryQuery($id: String!) {
    parts: allAirtable(
      filter: {
        table: { eq: "Parts" }
        data: { Part_Category: { elemMatch: { id: { eq: $id } } } }
      }
    ) {
      edges {
        node {
          data {
            Part_Number
            Part_Variation {
              data {
                Variation
              }
            }
            Part_Description
            Part_Equivalent_1
            Part_Equivalent_2
            Part_Equivalent_3
          }
          id
        }
      }
    }
    category: allAirtable(
      filter: { table: { eq: "Categories" }, id: { eq: $id } }
    ) {
      edges {
        node {
          id
          data {
            Category_Name
            Category_Introduction
            Category_Details
            Category_Slug
            Category_Image {
              url
              thumbnails { large { url } }
            }
            Category_Types {
              id
              data {
                Type_Name
                Type_Slug
                Type_Image {
                  url
                  thumbnails { large { url } }
                }
              }
            }
          }
        }
      }
    }
  }
`
