import React from 'react'
import styled from '@emotion/styled'
import tw from 'twin.macro'
import PropTypes from 'prop-types'


const PartsCategoryHeadingComponent = ({ className, category }) => {
  return (
    <div className={className}>
      <h2>{category.introduction}</h2>
      <p>{category.details}</p>
    </div>
  )
}

PartsCategoryHeadingComponent.propTypes = {
  className: PropTypes.string.isRequired,
  category: PropTypes.object.isRequired,
}

const PartsCategoryHeading = styled(PartsCategoryHeadingComponent)`
  ${tw``}
`
export default PartsCategoryHeading
